<template>
  <b-modal
    id="select-widget-icon"
    v-model="modalShow"
    size="lg"
    centered
    no-stacking
    hide-footer
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    body-class="pt-0"
    header-class="pb-0 border-bottom-0"
    aria-labelledby="Widget icons select modal"
  >
    <template #modal-header>
      <div class="d-flex w-100 align-items-center justify-content-end">
        <div class="modal-right-items">
          <a href="javascript:void(0)" @click="$emit(`closeWidgetIconsModal`)"
            ><img src="../../../assets/img/close.png" alt="close-tab"
          /></a>
        </div>
      </div>
    </template>
    <div class="d-block px-3">
      <h3 class="font-21 theme-font-medium pb-2">Select icon</h3>
      <b-form-row class="mb-2">
        <b-col>
          <div class="floating-input-field">
            <b-form-input
              id="search-icons-list"
              class="mb-2 mb-sm-1"
              v-model="iconSearchText"
              @input="searchIconsList"
              autocomplete="off"
              placeholder=" "
              type="text"
            ></b-form-input>
            <label for="search-icons-list"
              >Search icon list <BIconSearch class="input-search-icon"
            /></label>
          </div>
        </b-col>
      </b-form-row>
      <b-form-row
        v-if="iconsList && iconsList.blobListCount"
      >
        <b-col
          cols="2"
          md="2"
          lg="1"
          v-for="(icon, index) in iconsList.blobList"
          :key="index"
          class="px-1 mb-2"
        >
          <div
            :class="{ selected: selectedIcon == icon.blobUri }"
            class="icon-list-wrapper"
            @click="selectedIcon = icon.blobUri"
          >
            <img
              :src="icon.blobUri"
              :alt="icon.name"
              :title="icon.name"
              class="widget-list-icon"
            />
          </div>
        </b-col>
        <div class="px-1 w-100">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="md"
            class="my-0"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </b-form-row>
      <b-form-row v-else>
        <b-col v-if="!loading">
          <h4 class="ml-1 page-sub-header">No icons available.</h4>
        </b-col>
      </b-form-row>
      <b-spinner
        v-if="loading"
        label="Spinning"
        small
        class="spinning text-center"
      ></b-spinner>
      <b-form-row class="my-4">
        <b-col>
          <b-button
            type="button"
            v-activeBlur
            @click="saveIcon"
            :disabled="!selectedIcon"
            variant="primary"
            >Save and close
          </b-button>
          <b-button
            type="button"
            v-activeBlur
            @click="$emit(`closeWidgetIconsModal`)"
            class="ml-3"
            variant="outline-secondary"
            >Cancel</b-button
          >
        </b-col>
      </b-form-row>
    </div>
  </b-modal>
</template>
<script>
import { BIconSearch } from 'bootstrap-vue'
export default {
  name: 'IconsList',
  props: ['savedIcon'],
  components: {
    BIconSearch
  },
  data () {
    return {
      selectedIcon: null,
      modalShow: true,
      loading: false,
      iconsList: null,
      iconSearchText: null,
      currentPage: 1,
      perPage: 36,
      totalRows: 0
    }
  },
  mounted () {
    this.getIconsList(false)
    this.selectedIcon = this.savedIcon
  },
  methods: {
    async getIconsList (searchText = false) {
      if (
        !this.iconsList ||
        (this.iconsList &&
          this.iconsList.blobList.length < this.iconsList.blobListCount)
      ) {
        this.loading = true
        try {
          const res = await this.$store.dispatch('widgets/getBlobIconList', {
            page: this.currentPage,
            searchText
          })
          this.loading = false
          this.iconsList = res.data
          this.totalRows = this.iconsList.blobListCount
        } catch (error) {
          this.loading = false
          this.iconsList = []
          this.totalRows = 0
        }
      }
    },
    handlePageChange (value) {
      this.currentPage = value
      this.getIconsList(this.iconSearchText)
    },
    searchIconsList () {
      this.currentPage = 1
      this.iconsList = null
      this.selectedIcon = null
      if (this.iconsContainerDiv) {
        this.iconsContainerDiv.scrollTop = 0
      }
      this.getIconsList(this.iconSearchText)
    },
    saveIcon () {
      this.onHidden()
      this.$emit('closeWidgetIconsModal', this.selectedIcon)
    },
    onHidden () {
      this.iconSearchText = null
      this.currentPage = 1
      this.iconsList = null
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-list-wrapper {
  // width: 55px;
  height: 55px;
  padding: 0.75rem;
  border: 1px solid #c6c6c6;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .widget-list-icon {
    width: 25px;
    height: 25px;
    filter: invert(1);
  }
}
.icon-list-wrapper.selected {
  box-shadow: 2px 2px 3px #0000008e;
  border: 1px solid #000000;
}
.spinning {
  display: block;
  margin: 0 auto;
}
</style>
